import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import startCase from 'lodash/startCase';

import translateTag from '../utils';

import Layout from '../components/Layout';
import BlogPostCard from '../components/BlogPostCard';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import Pagination from '../components/Pagination';

const TagPage = ({ data, pageContext }) => {
  const title = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;
  const {
    tag,
    // currentPage,
    prevPagePath,
    nextPagePath,
    hasPrevPage,
    hasNextPage,
  } = pageContext;

  return (
    <>
      <Helmet title={`${tag} | ${title}`} />
      <Layout>
        <Img fluid={data.file.childImageSharp.fluid} />
        <section className="section">
          <div className="container">
            <div className="section">
              <h1
                className="has-text-weight-bold is-size-1"
                style={{ marginBottom: '1rem' }}
              >
                {startCase(translateTag(tag))}
              </h1>

              <div className="columns is-multiline">
                {posts.map(({ node: post }) => {
                  const className = `blog-list-item tile is-child ${
                    post.frontmatter.featuredpost ? 'is-featured' : ''
                  }`;

                  const featuredImage = post.frontmatter.featuredimage ? (
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.title}`,
                        }}
                      />
                    </div>
                  ) : null;

                  return (
                    <div className="column is-6" key={post.id}>
                      <BlogPostCard
                        className={className}
                        featuredImage={featuredImage}
                        link={post.fields.slug}
                        title={post.frontmatter.title}
                        date={post.frontmatter.date}
                        excerpt={post.excerpt}
                      />
                    </div>
                  );
                })}
              </div>
              <Pagination
                prevPagePath={prevPagePath}
                nextPagePath={nextPagePath}
                hasPrevPage={hasPrevPage}
                hasNextPage={hasNextPage}
              />
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default TagPage;

export const tagPageQuery = graphql`
  query TagPage(
    $tag: String
    $tagImageRegex: String
    $postsLimit: Int!
    $postsOffset: Int!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    file(name: { regex: $tagImageRegex }) {
      name
      relativePath
      childImageSharp {
        fluid(maxWidth: 1280, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(
      limit: $postsLimit
      skip: $postsOffset
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: [$tag] }, templateKey: { eq: "blog-post" } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            title
            description
            date(formatString: "DD MMMM YYYY", locale: "bg")
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 640, maxHeight: 350, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt(format: PLAIN, pruneLength: 200)
        }
      }
    }
  }
`;
