const translateTag = (tag) => {
  const map = {
    asanas: 'асани',
    yoga: 'йога',
  };

  if (map[tag]) return map[tag];
  return tag;
};

export default translateTag;
